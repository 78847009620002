.gh-post-upgrade-cta-content,
.gh-post-upgrade-cta {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-color: var(--background-highlight-color);
}

.gh-post-upgrade-cta-content {
	padding: var(--unit-small) var(--unit-medium);
}

.gh-post-upgrade-cta {
	& h2 {
		margin: var(--unit-tiny) 0;
		font-weight: 700;
	}

	& p {
		margin: var(--unit-tiny) 0 0;
	}

	& a {
		color: var(--color-link);
		text-decoration: underline;
		cursor: pointer;
		text-decoration-color: var(--color-link-underline);

		&:hover {
			color: var(--color-link-hover);
			text-decoration-color: var(--color-link-hover-underline);
		}
	}

	a.gh-btn {
		display: block;
		margin: var(--small) 0 0;
		padding: calc(var(--tiny) / 2) var(--tiny);
		color: var(--color-button);
		font-weight: 700;
		text-decoration: none;
		background-color: var(--color-button-background);
		border-radius: calc(var(--tiny) / 4);
		transition: background-color var(--animation-duration-short) var(--animation-base);

		&:hover {
			color: var(--color-button-hover);
			background-color: var(--color-button-background-hover);
		}
	}
}
