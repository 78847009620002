html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	min-width: calc(var(--tiny) * 22);
	color: var(--color-text);
	font-family: var(--font);
	font-size: var(--font-size);
	line-height: var(--line-height);
	text-rendering: optimizeLegibility;
	word-break: break-word;
	background-color: var(--background-color);
	transition: background-color var(--animation-duration-short) var(--animation-base);

	& > main {
		margin: 0 auto;
		max-width: var(--screen-large);
	}
}

.visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	white-space: nowrap;
	clip-path: inset(50%);
}

a {
	color: var(--color-link);
	text-decoration: none;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
figcaption,
li,
li::before,
.content strong,
.logo-text,
.canvas-close,
.error-code,
.kg-bookmark-title,
.kg-bookmark-author,
.kg-bookmark-description,
.kg-bookmark-publisher,
.menu-item-link,
.post-meta,
.post-link.secondary,
.page-number,
.author-location,
.author-social-item,
.button-arrow-icon {
	transition: color var(--animation-duration-short) var(--animation-base);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--color-text-heading);
}

h1 {
	font-size: 2.46rem;
	line-height: 3rem;
	font-weight: 700;
}

h2 {
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
}

h3 {
	font-size: 1.3rem;
	line-height: 1.65rem;
	font-weight: 700;
}

h4 {
	font-size: 1.2rem;
	font-weight: 800;
	text-transform: uppercase;
}

h5 {
	font-size: 1.2rem;
	font-weight: 700;
	text-transform: uppercase;
}

h6 {
	font-size: 1rem;
	font-weight: 800;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

blockquote {
	margin: 0;
	padding: var(--unit-tiny);
	color: var(--color-text-caption);
	background-color: var(--background-highlight-color);
}

ul,
ol {
	padding-left: 35px;
}

li {
	margin: 5px 0;
}

iframe,
embed {
	display: block;
	overflow: hidden;
	width: 100%;
	border: 0;
}

figure {
	margin: 0;
}

figcaption {
	margin-top: 15px;
	color: var(--color-text-caption);
	font-size: 14px;
	text-align: center;
}

pre {
	overflow-x: scroll;
	padding: 16px 22px;
	line-height: 1.5;
	white-space: pre;
	hyphens: none;
	background-color: var(--background-highlight-color);
	border-radius: 5px;
	-webkit-overflow-scrolling: touch;
}

code {
	font-family: var(--font-mono);
	font-size: 14px;
	word-break: keep-all;
}

:not(pre) > code {
	padding: 3px 4px;
	background-color: var(--background-highlight-color);
	border-radius: 3px;
	transition: background-color var(--animation-duration-short) var(--animation-base);
}

hr {
	margin: var(--unit-medium) 0;
	width: 100%;
	height: 1px;
	border: none;
	background-color: var(--border-color);
}

td,
th {
	padding: 0;
}
