/* .post {
    & .author {
        display: flex;
        margin-top: 60px;
    }

    & .author + .author {
        margin-top: 30px;
    }

    & .author-image-placeholder {
        flex-shrink: 0;
        overflow: hidden;
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    & .author-wrapper {
        margin-left: 25px;
        max-width: 550px;
    }

    & .author-header {
        display: flex;
        align-items: center;
    }

    & .author-header-wrapper {
        position: relative;
    }

    & .author-name {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 800;
        cursor: pointer;
    }

    & .author-name::after {
        content: "\e92e";
        margin-left: 1px;
        font-family: Alto, sans-serif;
        font-size: 19px;
    }

    & .author-social {
        position: absolute;
        top: 100%;
        left: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-left: -26px;
        padding: 8px 0;
        font-size: 12px;
        line-height: 1;
        background-color: var(--color-navigation-hover);
        border-radius: 3px;
        opacity: 0;
        transition: opacity var(--animation-duration-short) var(--animation-base), transform var(--animation-duration-short) var(--animation-base);
        transform: translateY(5px);
        pointer-events: none;
    }

    & .author-social::before {
        content: "\e900";
        position: absolute;
        top: -12px;
        left: 7px;
        color: var(--color-navigation-hover);
        font-family: Alto, sans-serif;
        font-size: 19px;
    }

    & .author-social.enabled {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    }

    & .author-social-item {
        padding: 5px 11px;
        color: var(--white-color);
        font-weight: 700;
        white-space: nowrap;
    }

    & .author-location-icon {
        position: relative;
        top: 1px;
    }

    & .author-location {
        margin-top: 12px;
        margin-left: -2px;
        color: var(--secondary-text-color);
        font-size: 11px;
        font-weight: 800;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
}
 */

main.single article.author {
	--author-card-size: 220px;

	margin-top: var(--unit-medium);
	margin-right: auto;
	margin-left: auto;
	padding-top: var(--unit-medium);
	max-width: var(--screen-medium);
	border-top: 1px solid var(--border-color);
	grid-template-columns: min-content minmax(auto, var(--screen-large));
	grid-template-areas:
		"media header"
		"media description";

	@media (--lt-small) {
		grid-template-columns: minmax(auto, var(--screen-small));
		grid-template-areas:
			"media"
			"header"
			"description";
	}

	& + article.author {
		margin-top: 0;
		border-top: none;
	}
}

article.author {
	--author-card-size: 286px;
	--oblong-ratio: 1.12;

	display: grid;
	margin: 0 auto var(--unit-small) auto;
	margin: 0 var(--unit-small);
	grid-template-columns: min-content minmax(auto, var(--screen-large));
	grid-template-areas:
		"cover cover"
		"media header"
		"media description";

	@media (--lt-small) {
		grid-template-columns: minmax(auto, var(--screen-small));
		grid-template-areas:
			"cover"
			"media"
			"header"
			"description";
	}

	& > h1,
	& > h3 {
		align-self: center;
		margin: var(--unit-tiny) var(--unit-medium);
		grid-area: header;

		@media (--lt-small) {
			margin-bottom: var(--unit-small);
			text-align: center;
		}
	}

	& > .author-cover {
		max-height: 300px;
		grid-area: cover;

		& .ratio-long {
			width: 100%;

			& img {
				width: 100%;
				height: 300px;
				object-fit: cover;
			}
		}
	}

	& > .author-cover + .author-photo {
		margin-top: calc(-1 * var(--unit-medium));
	}

	& > .author-photo {
		align-self: beginning;
		margin: 0 calc(var(--unit-small)) 0 calc(var(--unit-medium));
		min-width: calc(var(--author-card-size) * var(--oblong-ratio));
		width: calc(var(--author-card-size) * var(--oblong-ratio));
		grid-area: media;

		@media (--lt-small) {
			justify-self: center;
		}

		& .ratio-oblong {
			perspective: 150px;

			& .playful {
				transform: rotateX(-0.5deg) rotateY(0.8deg);

				@media (--lt-ample) {
					transform: rotateX(-0.75deg) rotateY(-0.2deg);
				}
			}

			& img {
				width: calc(var(--author-card-size) * var(--oblong-ratio) - var(--unit-tiny) / 2);
				height: calc(var(--author-card-size) - var(--unit-tiny) / 2);
				border: calc(var(--unit-tiny) / 2) solid var(--background-color);
				transition:
					border-color var(--animation-duration-short) var(--animation-base),
					transform var(--animation-duration-long) var(--animation-base);
				object-fit: cover;
			}
		}

		& img {
			object-fit: cover;
		}
	}

	&:hover {
		& > .author-photo > .ratio-oblong img {
			border-color: var(--background-highlight-color);
			background-color: var(--background-highlight-color);
		}
	}

	& .author-description {
		align-self: beginning;
		margin: 0 var(--unit-small);
		padding: var(--unit-small);
		max-width: var(--screen-small);
		border-top: 1px solid var(--border-color);
		white-space: pre-line;
		transition: border-color var(--animation-duration-short) var(--animation-base);
		grid-area: description;

		@media (--lt-small) {
			border-bottom: 1px solid var(--border-color);
		}
	}
}
