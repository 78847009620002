body > header {
	margin: 0 auto var(--tiny) auto;
	padding: 0 var(--unit-small);
	max-width: calc(var(--screen-large) - var(--medium) * 2);

	@media (--lt-ample) {
		padding-left: 0;
	}
}

.navbar {
	display: flex;
	height: 96px;

	& .navbar-right {
		display: flex;
		align-items: center;
	}

	& .navbar-left {
		position: relative;
		flex-grow: 1;
		display: flex;
		align-items: center;

		& .logo {
			margin-right: var(--tiny);
			width: var(--logo-width);
			transition: transform var(--animation-duration-long) var(--animation-base);
			transform: translateX(0);

			@media (--lt-ample) {
				margin-left: var(--small);
				transform: translateX(var(--logo-width));
			}

			& .logo-image {
				margin-top: var(--tiny);
			}
		}

		& #main-menu-toggle {
			position: absolute;
			left: 0;
			padding-top: 8px;
			height: 100%;
			opacity: 0;
			transition: opacity var(--animation-duration-long) var(--animation-base);

			@media (--lt-ample) {
				padding-left: var(--tiny);
				opacity: 1;
			}

			& svg path,
			& svg fill { transition: fill var(--animation-duration-short) var(--animation-base); }
			& svg path { fill: var(--background-opposite-highlight-color); }
			& svg circle { fill: var(--background-color); }

			&:hover {
				svg path { fill: var(--background-opposite-color); }
				svg circle { fill: var(--background-highlight-color); }
			}
		}

		& nav.main-menu:target,
		& nav.main-menu[aria-expanded="true"] {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
			overflow: -moz-scrollbars-none;
			overflow-x: visible;
			overflow-y: auto;
			width: 200px;
			height: 100%;
			outline: none;
			background-color: var(--background-color);
			box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
			transition: transform var(--animation-duration-medium) var(--animation-base);
			transform: translateX(0);
			scrollbar-width: none;

			& > ul {
				display: flex;
				flex-direction: column;

				& > li > a {
					line-height: 3;

					&:hover {
						background-color: var(--background-highlight-color);
					}
				}
			}
		}

		& nav.main-menu:target + .backdrop,
		& nav.main-menu[aria-expanded="true"] + .backdrop {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 998;
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.33);
			cursor: default;
			backdrop-filter: blur(10px);
		}
	}

	& .sep {
		display: flex;
		margin-right: var(--unit-tiny);
		margin-left: var(--unit-tiny);
		width: 1px;
		height: 100%;

		&::after {
			content: "";
			margin: auto;
			width: 100%;
			height: 40%;
			background-color: var(--border-color);
			transition: background-color var(--animation-duration-short) var(--animation-base);

			@media (--lt-ample) {
				background-color: var(--background-color);
			}
		}
	}
}

nav.main-menu {
	flex: 1;

	@media (--lt-ample) {
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		height: 100%;
		transform: translateX(-200px);

		& > ul {
			flex-direction: column;
		}
	}

	& > ul {
		display: flex;
		justify-content: space-between;
		padding: 0;
		list-style-type: none;

		& > li {
			display: block;
			height: auto;
			font-weight: 700;
			letter-spacing: 0.5px;
			text-align: center;

			& > a {
				display: block;
				padding: 0 var(--unit-tiny);
				width: 100%;
				color: var(--color-navigation);
				line-height: 4;
				white-space: nowrap;

				&:hover {
					color: var(--color-navigation-hover);
				}
			}
		}
	}
}
