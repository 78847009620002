figure.post-media {
	position: relative;

	--caption-opacity: 0.9;

	& figcaption {
		position: absolute;
		right: var(--caption-gap, var(--unit-tiny));
		bottom: var(--caption-gap, var(--unit-tiny));
		overflow: auto;
		margin-left: var(--caption-gap, var(--unit-tiny));
		padding: 4px 6px;
		max-width: 100%;
		max-height: 80%;
		color: var(--color-text-dark);
		font-weight: 700;
		text-overflow: ellipsis;
		background-color: var(--light-background-color);
		opacity: var(--caption-opacity);
		transition: opacity var(--animation-duration-medium) var(--animation-base);
		pointer-events: none;
		will-change: opacity;

		&.hidden {
			--caption-opacity: 0;
		}

		&:hover {
			--caption-opacity: 0.99;
		}

		& em {
			display: block;
			font-weight: normal;
			font-style: normal;
		}

		& strong {
			display: block;
			font-size: 200%;
			line-height: 100%;
			font-weight: 800;
		}

		& > a.post-caption-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
