@import "theme-light.css";
@import "theme-dark.css";

:root {
	@mixin theme-light;
}

:root[data-theme=dark] {
	@mixin theme-dark;
}

@media (prefers-color-scheme: dark) {
	:root[data-theme=auto] {
		@mixin theme-dark;
	}
}
