.toggle-track {
	position: relative;
	display: flex;
	margin-left: 36px;
	width: 45px;
	height: 24px;
	user-select: none;
	background-color: var(--color-navigation-hover);
	border-radius: 30px;
	cursor: pointer;

	@at-root {
		:root[data-theme=dark] .toggle-track {
			background-color: var(--background-highlight-color);
		}
	}
}

@media (prefers-color-scheme: dark) {
	:root[data-theme=auto] .toggle-track {
		background-color: var(--background-highlight-color);
	}
}

.toggle-sun,
.toggle-moon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1px;
	width: 50%;
	color: #f1f3f4;
	font-size: 1rem;
}

.toggle-moon {
	transform: translateX(1px);
}

.toggle-thumb {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 20px;
	background-color: var(--light-background-color);
	border-radius: 50%;
	transition: transform var(--animation-duration-long) cubic-bezier(0.23, 1, 0.32, 1);

	@at-root {
		:root[data-theme=dark] .toggle-thumb {
			transform: translateX(21px);
		}
	}
}

@media (prefers-color-scheme: dark) {
	:root[data-theme=auto] .toggle-thumb {
		transform: translateX(21px);
	}
}
