.post-feed {
	& .post {
		display: grid;
		width: 100%;
		border: var(--unit-small) solid transparent;
		background-color: transparent;
		transition:
			background-color var(--animation-duration-short) var(--animation-base),
			border-color var(--animation-duration-short) var(--animation-base),
			border-width var(--animation-duration-medium) var(--animation-base);
		grid-template-columns: minmax(480px, 640px) minmax(480px, 540px);
		grid-template-areas: 'image', 'content';

		&.no-image {
			display: block;
		}

		@media (--lt-ample) {
			display: block;
			overflow: hidden;
			margin: 0 auto;
			max-width: calc(var(--screen-ample) * 0.7);
		}

		@media (--lt-tiny) {
			max-width: 100%;
			border-right-width: 0;
			border-left-width: 0;
		}

		.playful {
			transition: transform var(--animation-duration-medium) var(--animation-base);
			transform: rotateX(0) rotateY(0);
		}

		&:hover {
			border-color: var(--background-highlight-color);
			background-color: var(--background-highlight-color);

			--caption-opacity: 1;

			.playful {
				transform: rotateX(0) rotateY(0.9deg);

				@media (--lt-ample) {
					transform: rotateX(-0.5deg) rotateY(0);
				}
			}
		}

		& .perspective-container {
			perspective: 300px;
		}

		& .post-media {
			@media (--lt-ample) {
				margin-bottom: var(--tiny);
				max-height: 288px;
				width: 100%;
				height: auto;
			}

			& img {
				min-height: 288px;
				width: 100%;
				height: auto;
				object-fit: cover;

				@media (--lt-small) {
					max-height: 150px;
				}

				@media (--lt-medium) {
					max-height: 288px;
				}

				@media (--gt-medium) {
					max-height: 306px;
				}
			}
		}

		& .post-header {
			& .post-title {
				margin: 0 0 0 0;
				font-weight: 700;

				& > a {
					display: block;
					padding: 0 0 var(--unit-tiny) var(--unit-small);
					width: 100%;
					font-size: 2.46rem;
					line-height: 120%;
					transition: font-size var(--animation-duration-medium) var(--animation-base);

					@media (--lt-large) {
						font-size: 2rem;
					}

					@media (--lt-ample) {
						font-size: 1.8rem;
						transition: none;
					}
				}
			}

			& .post-meta {
				padding: 0 var(--unit-small);
			}
		}

		& .post-excerpt > a {
			display: block;
			padding: var(--unit-tiny) var(--unit-small);
			color: var(--color-text);
			white-space: pre-line;
		}
	}

	& .post-footer {
		margin: 0 0 0 calc(var(--unit-small) / 2);
	}
}
