:root {
	--logoColor: var(--color-text-dark);
}

:root[data-theme=dark] {
	--logoColor: var(--light-background-color);
}

@media (prefers-color-scheme: dark) {
	:root[data-theme=auto] {
		--logoColor: var(--light-background-color);
	}
}

.logo {
	display: block;
	perspective: 30px;
}

.logo-text {
	font-size: 1.5rem;
	font-weight: 800;
	white-space: nowrap;
}

.logo-image {
	margin-top: var(--unit-tiny);
	width: 75px;
	height: 80px;

	--logoBackgroundColor: var(--background-color);

	& + .logo-text {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		width: 0;
		height: 0;
	}
}

.logo-image:hover {
	--logoBackgroundColor: var(--background-highlight-color);
}

svg.logo-image .logo-image-background {
	fill: var(--logoBackgroundColor, none);
}

svg.logo-image .logo-image-foreground {
	fill: var(--logoColor);
}

:root[data-theme=dark] .logo-image {
	opacity: 0.9;
}

svg.logo-image * {
	transition: fill var(--animation-duration-short) var(--animation-base);
}
