.featured-pages {
	margin-top: var(--tiny);
	margin-bottom: var(--small);

	& .post-excerpt,
	& .post-content {
		margin: 0 auto;
		max-width: var(--screen-medium);
		border-top: 1px solid var(--border-color);
		border-bottom: 1px solid var(--border-color);
		transition: border-color var(--animation-duration-short) var(--animation-base);
	}

	& article + article {
		& .post-excerpt,
		& .post-content {
			margin-top: 0;
			border-top: none;
		}
	}

	& .post-content {
		padding: var(--unit-small);
	}

	& .post-excerpt > a {
		display: block;
		padding: calc(var(--unit-small) - 3px) var(--unit-small);
		border-top: 4px solid var(--background-color);
		border-bottom: 3px solid var(--background-color);
		color: var(--color-text);
		text-align: center;
		transition: border-color var(--animation-duration-short) var(--animation-base);

		&:hover {
			border-color: var(--background-highlight-color);
		}
	}
}
