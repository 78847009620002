article.tag {
	--tag-card-size: 320px;
	--oblong-ratio: 1.12;

	display: grid;
	margin: 0 calc(var(--unit-tiny) / 2) var(--unit-medium) calc(var(--unit-tiny) / 2);
	grid-template-columns: calc(var(--tag-card-size) * var(--oblong-ratio)) minmax(auto, var(--screen-small));
	grid-template-areas:
		"media header"
		"media description";

	@media (--lt-ample) {
		grid-template-columns: 100%;
		grid-template-areas:
			"media"
			"header"
			"description";
	}

	& > h1 {
		margin: var(--unit-small) var(--unit-medium);
		grid-area: header;

		@media (--lt-ample) {
			text-align: center;
		}
	}

	& > .tag-media {
		align-self: center;
		justify-content: center;
		grid-area: media;
		perspective: 200px;

		& > .playful {
			transform: rotateX(-0.3deg) rotateY(0.8deg);

			@media (--lt-ample) {
				transform: rotateX(-0.5deg) rotateY(0.25deg);
			}
		}

		& .ratio-oblong {
			margin: 0 auto;
			max-width: calc(var(--tag-card-size) * var(--oblong-ratio));
			border: var(--unit-tiny) solid var(--background-color);
			background-color: var(--background-color);
			transition:
				background-color var(--animation-duration-short) var(--animation-base),
				border-color var(--animation-duration-short) var(--animation-base),
				transform var(--animation-duration-long) var(--animation-base);

			& img {
				width: 100%;
				height: calc(var(--tag-card-size) - var(--unit-tiny) * 2);
				object-fit: cover;
			}
		}

		& img {
			object-fit: cover;
		}
	}

	&:hover > .tag-media .ratio-oblong {
		border-color: var(--background-highlight-color);
		background-color: var(--background-highlight-color);
	}

	& .tag-description {
		align-self: center;
		margin: var(--unit-small);
		padding: var(--unit-small);
		border-top: 1px solid var(--border-color);
		border-bottom: 1px solid var(--border-color);
		white-space: pre-line;
		transition: border-color var(--animation-duration-short) var(--animation-base);
		grid-area: description;
	}
}
