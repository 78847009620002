.post-author {
	display: flex;
	align-items: center;
	margin-right: calc(var(--unit-small) / 2);
	justify-self: right;
	color: var(--color-navigation);
}

.post-author-image-link {
	position: relative;
	display: block;
}

.post-author-image {
	display: absolute;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	object-fit: cover;

	&:hover {
		z-index: 50;
	}
}

.post-author-image-link:first-child {
	z-index: 10;
}

.post-author-image-link:nth-child(2) {
	z-index: 9;
	margin-left: -12px;
}

.post-author-image-link:nth-child(3) {
	z-index: 8;
	margin-left: -12px;
}

.post-author-image-link:nth-child(4) {
	z-index: 7;
	margin-left: -12px;
}

.post-author-image-link:nth-child(5) {
	z-index: 6;
	margin-left: -12px;
}

.post-author-name {
	margin-left: 7px;
	line-height: 1;
	font-weight: 700;

	& a {
		color: currentColor;
	}
}

.post-author-name-link {
	color: currentColor;

	&:hover {
		color: var(--color-navigation-hover);
	}
}

.post-author-name-link + .post-author-name-link::before {
	content: ", ";
	color: var(--color-navigation);
}
