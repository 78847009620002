main.single > .post {
	margin-bottom: var(--unit-medium);

	& .post-header h1.post-title {
		margin: calc(var(--unit-small) + var(--unit-tiny)) var(--unit-medium);
		text-align: center;
	}

	.post-meta {
		margin: var(--unit-small);
		text-align: center;
	}

	.perspective-container {
		perspective: 600px;

		@media (--lt-large) {
			overflow: hidden; /* prevent horizontal scrollbars */
		}

		& > .playful {
			transition: transform var(--animation-duration-long) var(--animation-base);
			transform: rotateX(-0.27deg) rotateY(0.1deg);
		}
	}

	& .post-media {
		--caption-gap: calc(var(--unit-small));

		margin: var(--unit-small) auto;
		border: var(--unit-small) solid var(--background-color);
		transition: border-color var(--animation-duration-short) var(--animation-base);

		img {
			margin: 0 auto;
			max-width: var(--screen-large);
			max-height: calc(var(--screen-large) / 2);
			width: 100%;
			height: auto;
			object-fit: cover;

			@media (--lt-ample) {
				object-fit: cover;
				min-height: 288px;
			}
		}

		&:hover {
			border-color: var(--background-highlight-color);
		}
	}
}

.post-meta {
	color: var(--color-text-unimportant);
	font-size: 12px;
	font-weight: 800;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	cursor: default;

	& .post-tag {
		color: var(--color-text-unimportant);
	}
}

.post-meta-item + .post-meta-item::before {
	content: "\02022";
	padding: 0 calc(var(--unit-tiny) / 4);
	font-family: serif;
}

.post-meta-length-short {
	display: none;
}

/* .post-tag + .post-tag::before {
    content: ", ";
} */

.post-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid var(--border-color);
}

.post-link {
	display: block;
	padding: var(--unit-tiny) calc(var(--unit-small) / 2);
	color: var(--color-navigation);
	font-size: var(--font-size-small);
	font-weight: 800;
	text-transform: uppercase;

	&:hover {
		color: var(--color-navigation-hover);
	}
}

.post-link.secondary::before {
	content: "\e933";
} /* not needed */

.pagination {
	display: flex;
	align-items: center;
	margin: var(--unit-small);

	&.pages-1 {
		display: none;
	}

	& .pagination-left,
	& .pagination-right {
		flex: 2;
		display: block;
		padding: var(--unit-tiny) calc(var(--unit-small) / 2);
		font-size: var(--font-size-small);
		font-weight: 800;
		text-transform: uppercase;

		& a {
			color: var(--color-navigation);

			&:hover {
				color: var(--color-navigation-hover);
			}
		}
	}

	& .pagination-right {
		text-align: right;
	}

	& .page-number {
		padding: var(--unit-tiny);
		border-top: 1px solid var(--border-color);
		border-bottom: 1px solid var(--border-color);
		color: var(--color-text-unimportant);
		font-weight: 700;
	}

	& .button-arrow-icon {
		display: inline-block;
		color: var(--color-navigation);
		vertical-align: top;
		transition: transform var(--animation-duration-short) var(--animation-base);
	}

	& .button-arrow-left .button-arrow-icon {
		margin-right: 2px;
		margin-left: -2px;
	}

	& .button-arrow-right .button-arrow-icon {
		margin-right: -2px;
		margin-left: 2px;
	}

	& .button-arrow-left:hover .button-arrow-icon {
		transform: translateX(-2px);
	}

	& .button-arrow-right:hover .button-arrow-icon {
		transform: translateX(2px);
	}
}
