.featured-posts {
	--post-card-size: calc(var(--screen-large) / 4);

	margin: 0 auto;
	max-width: var(--screen-large);

	& > header {
		display: none;
	}

	&.swiper {
		overflow: -moz-scrollbars-none;
		overflow-x: visible;
		overflow-y: visible !important;
		scrollbar-width: none;

		@media (--lt-large) {
			overflow-x: hidden;
		}

		--swiper-navigation-size: calc(var(--small) * 1.5);
		--swiper-navigation-color: white;

		& .swiper-wrapper {
			display: flex;
			justify-content: center;
			overflow-y: visible !important;
			margin: 0 auto;
			padding-left: 0; /* non-zero padding-left is inherited from general ul rule */

			@media (--lt-large) {
				justify-content: left;
				margin: 0;
			}
		}

		& .swiper-button-next {
			right: 0;
			padding-left: calc(var(--small) / 2);
		}

		& .swiper-button-prev {
			left: 0;
			padding-right: calc(var(--small) / 2);
		}

		& .swiper-button-next,
		& .swiper-button-prev {
			top: 0;
			margin-top: var(--tiny);
			width: calc(var(--small) * 2.5);
			height: calc(var(--post-card-size) - var(--tiny) * 2);
			text-align: center;
			opacity: 0.75;
			transition: opacity var(--animation-duration-short) var(--animation-base);
			will-change: opacity;

			&::after {
				font-family: 'prev-next';
				text-shadow: 0 0 1px black;
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.2);
				opacity: 0.99;
			}
		}

		& .swiper-button-disabled {
			opacity: 0;
		}
	}

	& .swiper-slide {
		margin: 0; /* non-zero margin i inherited from general li rule */
		width: var(--post-card-size);
		list-style-type: none;
		perspective: 200px;

		@media (--lt-large) {
			perspective: 270px;
		}
	}

	& .post {
		position: relative;
		z-index: 10;
		box-sizing: border-box;
		min-width: var(--post-card-size);
		border: var(--tiny) solid transparent;
		background-color: transparent;
		transition:
			background-color var(--animation-duration-short) var(--animation-base),
			border-color var(--animation-duration-short) var(--animation-base),
			transform var(--animation-duration-long) var(--animation-base);

		&.no-image {
			& .post-content,
			& .post-excerpt > a {
				display: block;
				overflow: auto;
				padding: var(--tiny);
				height: calc(var(--post-card-size) - var(--tiny) * 2);
				background-color:
					rgba(
						calc(var(--highlight) * 255),
						calc(var(--highlight) * 255),
						calc(var(--highlight) * 255),
						calc(0.02 + 0.03 * var(--highlight))
					);
			}
		}
	}

	& .post-media {
		--caption-gap: calc(var(--tiny) / 2);
	}

	& .ratio-square img {
		object-fit: cover;
		width: calc(var(--post-card-size) - var(--tiny) * 2);
		height: calc(var(--post-card-size) - var(--tiny) * 2);
	}

	& .post:hover,
	&.drag .post.active {
		z-index: 10;
		border-color: var(--background-highlight-color);
		background-color: var(--background-highlight-color);
	}

	& .post-title {
		margin: 0;
		font-size: 120%;
	}

	& .post-title-link {
		display: block;
		padding-top: calc(var(--tiny) / 2);
		height: calc(var(--line-height) * 1.2 * 2);
	}

	& .post-header {
		text-align: center;
	}

	& .post:hover {
		--caption-opacity: 1;
	}

	& .post-media-tag {
		position: absolute;

		/* TODO: make these units calc'ed from tiny/small */
		top: 0.5rem;
		right: 0.5rem;
		z-index: 10;
		padding: 0 0.25rem;
		color: var(--color-tag);
		font-size: var(--font-size-small);
		font-weight: 700;
		text-overflow: ellipsis;
		white-space: nowrap;
		background: var(--background-dark-color);

		&:hover {
			color: var(--color-tag-hover);
		}
	}
}
