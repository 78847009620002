.error {
	text-align: center;
}

.error-code {
	margin-bottom: -5px;
	color: var(--color-navigation-hover);
	font-size: 40px;
	font-weight: 700;
	letter-spacing: 2px;
}

.error-posts {
	margin-top: 60px;
}

.error-link {
	color: var(--accent-color) !important;
	font-weight: 700;
}
