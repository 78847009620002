.author-posts,
.related-posts {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	margin: var(--unit-tiny) auto;
	margin: 0 auto;
	padding: var(--unit-medium) 0 var(--unit-small) 0;

	--post-card-size: 320px;

	& .perspective-container {
		perspective: 200px;

		& .playful {
			transition: transform var(--animation-duration-long) var(--animation-base);
		}
	}

	& .post {
		min-width: var(--post-card-size);
		width: var(--post-card-size);
		border: var(--unit-tiny) solid var(--background-color);
		background-color: var(--background-color);
		transition: background-color var(--animation-duration-short) var(--animation-base), border-color var(--animation-duration-short) var(--animation-base);

		&.no-image {
			& .post-content,
			& .post-excerpt > a {
				display: block;
				overflow: auto;
				padding: var(--unit-tiny);
				height: calc(var(--post-card-size) - var(--unit-tiny) * 2);
				background-color:
					rgba(
						calc(var(--highlight) * 255),
						calc(var(--highlight) * 255),
						calc(var(--highlight) * 255),
						calc(0.02 + 0.03 * var(--highlight))
					);
			}
		}
	}

	& .post-media {
		--caption-gap: calc(var(--unit-tiny) / 2);
	}

	& .ratio-square img {
		width: calc(var(--post-card-size) - var(--unit-tiny) * 2);
		height: calc(var(--post-card-size) - var(--unit-tiny) * 2);
		object-fit: cover;
	}

	& .post:hover,
	&.drag .post.active {
		border-color: var(--background-highlight-color);
		background-color: var(--background-highlight-color);
	}

	& .post-title {
		margin: 0;
		font-size: 120%;
	}

	& .post-title-link {
		display: block;
		padding: calc(var(--unit-tiny) / 2) 0;
		height: calc(var(--font-size) * var(--line-height) * 2);
	}

	& .post-header {
		text-align: center;
	}

	& .post:hover {
		--caption-opacity: 0.99;
	}

	& .post-media-tag {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		z-index: 10;
		padding: 0 0.25rem;
		color: var(--color-tag);
		font-size: var(--font-size-small);
		font-weight: 700;
		text-overflow: ellipsis;
		white-space: nowrap;
		background: var(--background-dark-color);

		&:hover {
			color: var(--color-tag-hover);
		}
	}
}

/* TODO: merge this with .featured-posts */
