/* TODO: audit these */

input[type="text"],
input[type="password"],
input[type="email"] {
	padding: 0 15px;
	height: 50px;
	outline: none;
	border: 1px solid var(--border-color);
	font-size: 16px;
	border-radius: 5px;
	appearance: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
	border-color: var(--accent-color);
}
