.content > * {
	margin-bottom: 0;
}

/* .content > * + * {
    margin-top: var(--unit-tiny);
} */

.content > *:first-child {
	margin-top: 0 !important;
}

.content > * + p,
.content > * + ul,
.content > * + ol {
	margin-top: var(--unit-tiny);
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
	margin-top: var(--unit-small) !important;
}

.content {
	& h1 + p,
	& h2 + p,
	& h3 + p,
	& h4 + p,
	& h5 + p,
	& h6 + p,
	& h1 + table,
	& h2 + table,
	& h3 + table,
	& h4 + table,
	& h5 + table,
	& h6 + table {
		margin-top: var(--unit-tiny);
	}
}

.content a {
	color: var(--color-link);
	text-decoration: underline;
	text-decoration-color: var(--color-link-underline);

	&:hover {
		color: var(--color-link-hover);
		text-decoration-color: var(--color-link-hover-underline);
	}
}

.content ul,
.content ol {
	list-style-type: none;
}

.content ul li,
.content ol li {
	position: relative;
}

.content ul li::before,
.content ol li::before {
	position: absolute;
	background-color: var(--background-highlight-color);
	transition: background-color var(--animation-duration-short) var(--animation-base);
}

.content ul li::before {
	/* TODO: use svg maybe? */
	content: "";
	top: 11px;
	left: -18px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
}

.content ol {
	counter-reset: numbered-list;
}

.content ol > li::before {
	/* TODO: set these fixed pixel sizes to --unit based vars */
	content: counter(numbered-list);
	top: 2px;
	left: -34px;
	width: 24px;
	height: 24px;
	color: var(--color-text);
	font-size: 13px;
	line-height: 24px;
	font-weight: 800;
	text-align: center;
	border-radius: 50%;
	counter-increment: numbered-list;
}

.content table td {
	vertical-align: top;
}

.content td + td {
	padding-left: 4px;
}

.content table tr:hover td > code:only-child {
	color: var(--color-link-hover);
	font-weight: 700;
}

.content > .kg-card,
.content > .kg-card + * {
	margin-top: var(--unit-tiny);
}

.content hr + * {
	margin-top: var(--unit-small);
}

.content blockquote,
.content blockquote + * {
	margin-top: var(--unit-small);
}

.kg-image {
	margin-right: auto;
	margin-left: auto;
}

.kg-canvas {
	display: grid;
	grid-template-columns:
		[full-start]
		minmax(4vw, auto)
		[wide-start]
		minmax(auto, 105px)
		[main-start]
		min(750px, calc(100% - 8vw))
		[main-end]
		minmax(auto, 105px)
		[wide-end]
		minmax(4vw, auto)
		[full-end];
}

.kg-canvas > * {
	grid-column: main-start/main-end;
}

.kg-width-wide {
	grid-column: wide-start/wide-end;
}

.kg-width-full {
	grid-column: full-start/full-end;
}

.kg-gallery-container {
	display: flex;
	flex-direction: column;
}

.kg-gallery-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.kg-gallery-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.kg-gallery-row:not(:first-of-type) {
	margin: 10px 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
	margin: 0 0 0 10px;
}

.kg-bookmark-container {
	display: flex;
	overflow: hidden;
	border: 1px solid var(--border-color);
	text-decoration: none !important;
	transition:
		background-color var(--animation-duration-short) var(--animation-base),
		border-color var(--animation-duration-short) var(--animation-base);

	&:hover {
		border-color: var(--background-highlight-color);
		background-color: var(--background-highlight-color);
	}
}

.kg-bookmark-container::after {
	display: none;
}

.kg-bookmark-content {
	flex-grow: 1;
	overflow: hidden;
	padding: 25px;
}

.kg-bookmark-title {
	color: var(--color-navigation-hover);
	font-size: 18px;
	line-height: 1.3;
	font-weight: 700;
}

.kg-bookmark-description {
	overflow: hidden;
	margin-top: 5px;
	color: var(--secondary-text-color);
	text-overflow: ellipsis;
	white-space: nowrap;
}

.kg-bookmark-metadata {
	display: flex;
	align-items: center;
	margin-top: 5px;
	font-size: 14px;
}

.kg-bookmark-icon {
	margin-right: 5px;
	width: 20px;
	height: 20px;
}

/* These classes will be deprecated. Keep them for GScan validation. */
.kg-bookmark-author,
.kg-bookmark-publisher {
	display: inline;
}

.kg-bookmark-metadata > span {
	color: var(--color-text-unimportant);
}

.kg-bookmark-metadata > span:nth-of-type(2)::before {
	content: "•";
	padding: 0 5px;
}

.kg-bookmark-thumbnail {
	position: relative;
	flex-shrink: 0;
	margin-left: 20px;
	width: 300px;
}

.kg-bookmark-thumbnail img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (--lt-small) {
	.kg-bookmark-container {
		flex-direction: column;
	}

	.kg-bookmark-thumbnail {
		margin-left: 0;
		width: 100%;
		height: 200px;
	}
}
