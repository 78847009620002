@define-mixin theme-light {
	--background-color: var(--light-background-color);
	--background-highlight-color: var(--light-background-highlight-color);
	--background-dark-color: var(--dark-background-highlight-color);
	--background-opposite-color: var(--dark-background-color);
	--background-opposite-highlight-color: var(--dark-background-highlight-color);
	--border-color: var(--palette-2);
	--highlight: 0;
	--color-text: var(--palette-7);
	--color-text-unimportant: var(--palette-5);
	--color-text-heading: var(--palette-6);
	--color-text-dark: var(--palette-8);
	--color-text-caption: var(--palette-7);
	--color-link: var(--palette-6);
	--color-link-underline: var(--palette-3);
	--color-link-hover: var(--palette-8);
	--color-link-hover-underline: var(--palette-4);
	--color-navigation: var(--palette-5);
	--color-navigation-hover: var(--palette-7);
	--color-button: var(--palette-2);
	--color-button-hover: var(--palette-0);
	--color-button-background: var(--palette-7);
	--color-button-background-hover: var(--palette-6);
	--color-tag: var(--palette-3);
	--color-tag-hover: var(--palette-1);
}
