body > footer {
	display: grid;
	justify-content: space-between;
	margin: var(--unit-small) auto 0 auto;
	padding: var(--unit-small) var(--unit-tiny) var(--unit-small) var(--unit-small);
	max-width: var(--screen-large);
	border-top: 1px solid var(--border-color);
	line-height: 1;
	grid-template-areas: "logo secondary-menu social-menu";
	grid-template-columns: var(--logo-width) 1fr 101px;

	@media (--lt-huge) {
		margin-bottom: var(--unit-medium);
	}

	@media (--lt-tiny) {
		margin-bottom: calc(var(--unit-large) * 2);
		grid-template-areas:
			"logo secondary-menu"
			"logo social-menu";
		grid-template-columns: var(--logo-width) 1fr;
		grid-template-rows: auto auto;
	}

	& .logo {
		grid-area: logo;
	}
}

nav.secondary-menu {
	grid-area: secondary-menu;
	align-self: center;

	& > ul {
		display: flex;
		justify-content: space-evenly;
		margin: 0 var(--unit-large);
		padding: 0;
		list-style-type: none;

		@media (--lt-huge) {
			margin: 0 var(--unit-medium);
		}

		@media (--lt-ample) {
			margin: 0 var(--unit-tiny);
		}

		@media (--lt-medium) {
			flex-direction: column;
		}

		& > li {
			display: block;
			height: auto;
			text-align: center;

			& > a {
				display: block;
				padding: 0 var(--unit-tiny);
				width: 100%;
				color: var(--color-navigation);
				line-height: 4;
				font-weight: 700;
				letter-spacing: 0.5px;
				white-space: nowrap;

				@media (--lt-medium) {
					line-height: 2;
				}

				&:hover {
					color: var(--color-navigation-hover);
				}
			}
		}
	}
}

nav.social-menu {
	grid-area: social-menu;
	align-self: center;

	@media (--lt-medium) {
		align-self: normal;
		margin: var(--unit-tiny) auto;
	}

	@media (--lt-tiny) {
		align-self: center;
	}

	& a {
		padding: var(--tiny);
		color: var(--color-navigation);

		&:hover {
			color: var(--color-navigation-hover);
		}
	}
}
