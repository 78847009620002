.related-title {
	margin: var(--unit-small) auto 0 auto;
	padding: var(--unit-small) var(--unit-small) 0 var(--unit-small);
	max-width: var(--screen-medium);
	border-top: 1px solid var(--border-color);
	transition: border-color var(--animation-duration-short) var(--animation-base);
}

section.related-posts {
	margin: var(--unit-medium) auto 0 auto;
	padding-top: var(--unit-medium);
	border-top: 1px solid var(--border-color);
	transition: border-color var(--animation-duration-short) var(--animation-base);
}

.related-title + section.related-posts {
	border-top: none;
}
