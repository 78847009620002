@custom-media --gt-huge   (min-width: 1793px);
@custom-media --lt-huge   (max-width: 1792px);
@custom-media --gt-large  (min-width: 1281px);
@custom-media --lt-large  (max-width: 1280px);
@custom-media --gt-ample  (min-width: 1025px);
@custom-media --lt-ample  (max-width: 1024px);
@custom-media --gt-medium (min-width:  961px);
@custom-media --lt-medium (max-width:  960px);
@custom-media --lt-small  (max-width:  768px);
@custom-media --lt-tiny   (max-width:  640px);

:root {
	--screen-huge: 1792px;
	--screen-large: 1280px;
	--screen-ample: 1024px;
	--screen-medium: 960px;
	--screen-small: 768px;
	--screen-tiny: 640px;
	--animation-duration-short: 0.2s;
	--animation-duration-medium: 0.3s;
	--animation-duration-long: 0.7s;
	--animation-base: ease-in-out;
	--tiny: 16px;
	--small: 24px; /* should be applied in pairs  */
	--medium: 48px;
	--large: 96px;
	--unit-tiny: calc(var(--tiny) * var(--unit-ratio, 1));
	--unit-small: calc(var(--small) * var(--unit-ratio, 1));
	--unit-medium: calc(var(--medium) * var(--unit-ratio, 1));
	--unit-large: calc(var(--large) * var(--unit-ratio, 1));

	@media (--lt-small) {
		--unit-ratio: 0.75;
	}

	@media (--lt-tiny) {
		--unit-ratio: 0.5;
	}

	--font-size: 16px;
	--font-size-small: 14px;
	--line-height: 1.65;
	--font: MulishVariable, Open Sans, Roboto, Montserrat, Lato, Oswald, Fira Sans, Aller, Source Sans Pro, Trebuchet MS, Allerta, PT Sans, Helvetica, sans-serif; /* stylelint-disable-line value-keyword-case */
	--font-mono: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace; /* stylelint-disable-line value-keyword-case */
	--logo-width: 75px;
	--logo-height: 80px;
	--palette-0: #fff;
	--palette-1: #fbfffb;
	--palette-2: #f1f3f4;
	--palette-3: #d9d9d9;
	--palette-4: #9d9090;
	--palette-5: #7e7171;
	--palette-6: #3f434e;
	--palette-7: #282c35;
	--palette-8: #1b1918;
	--palette-9: #fde7ac;
	--palette-A: #ba0000;
	--light-background-color: var(--palette-1);
	--light-background-highlight-color: var(--palette-2);
	--dark-background-color: var(--palette-7);
	--dark-background-highlight-color: var(--palette-6);
	--accent-color: var(--ghost-accent-color, var(--palette-6));
}

.pswp {
	--pswp-root-z-index: 4000000 !important;
}
